
import Widget from '@/components/Widget/Widget.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import apexchart from 'vue-apexcharts'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {
    Widget,
    apexchart,
  },
})
export default class MainChart extends ViewModel {
  @Prop({
    default: () => ({
      labels: [],
      impressions: [],
    }),
  })
  public payload: any

  public loaded: boolean = false

  public chartOptions: any = {
    chart: {
      id: 'devices',
      animations: {
        enabled: !this.user.isSystem,
      },
    },
    labels: [],
    legend: {
      position: 'right',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '40%',
        },
      },
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
  }

  public series: any = []

  public labels: any = []

  @Watch('payload')
  public onDataChange() {
    this.reloadChart()
  }

  public mounted() {
    this.reloadChart()
  }

  public reloadChart() {
    this.loaded = false
    if (!this.payload) return

    this.chartOptions = {
      ...this.chartOptions,
      ...{
        labels: this.payload.map((o: any) => o.name),
      },
    }
    this.series = this.payload.map((o: any) => o.value)

    this.loaded = true
  }
}
