
import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Filter from '@/models/Filter'
import QueryFilter from '@/components/QueryFilter/QueryFilter.vue'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import DashboardDataDetail from '@/models/DashboardDataDetail'

@Component({
  components: {
    QueryFilter,
    Widget,
    IconAction,
  },
})
export default class Detail extends ViewModel {
  @Prop({
    default: () => new Filter(),
  })
  public filters!: Filter

  @Prop({ default: null })
  public data!: any

  @Prop()
  public busy!: boolean

  @Prop({
    default: () => ({
      lg: 4,
      md: 6,
      sm: 12,
      xs: 12,
    }),
  })
  public size!: any

  public local_data = new DashboardDataDetail()

  @Watch('data')
  public onUpdateData() {
    this.local_data = DashboardDataDetail.toObject(this.data)
  }

  public modal: any = {
    filter: false,
  }

  public local_filters: Filter = new Filter()

  public editFilters() {
    this.local_filters = new Filter(this.filters)
    this.modal.filter = true
  }

  public apply() {
    this.modal.filter = false
    this.$emit('run', this.local_filters)
  }
}
