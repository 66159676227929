
import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import Widget from '@/components/Widget/Widget.vue'
import ItemData from './components/ItemData.vue'

@Component({
  components: {
    IconAction,
    ItemData,
    Widget,
  },
})
export default class OrdersView extends ViewModel {
  @Prop({ default: () => [] })
  public data!: any

  @Prop({
    default: () => ({}),
  })
  public callback_data!: any

  @Prop({ default: null })
  public settings!: any

  @Prop({ default: '' })
  public title!: string

  @Prop({ default: false })
  public busy!: boolean

  public advertisers: string[] = []

  @Prop({ default: 'default' })
  public mode!: string

  @Prop({ default: () => false })
  public showItems!: boolean

  @Prop({ default: () => false })
  public showCreatives!: boolean

  @Prop({ default: '' })
  public sortBy!: any

  @Prop({ default: false })
  public sortDesc!: any

  public get localSortBy() {
    return this.sortBy
  }

  public set localSortBy(value: any) {
    this.$emit('update:sortBy', value)
  }

  public get localSortDesc() {
    return this.sortDesc
  }

  public set localSortDesc(value: any) {
    this.$emit('update:sortDesc', value)
  }

  public get showDownload() {
    return false && !this.busy && this.user.isSuperAdmin
  }

  public getCallback(advertiser_id: string) {
    if (this.callback_data && this.callback_data.cb.advertiser_id === advertiser_id) {
      return this.callback_data
    }
    return null
  }
}
