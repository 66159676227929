import User from './User'
import Company from './Company'
import Order from './Order'
import LineItem from './LineItem'

export default class DashboardDataDetail {
  public account_executives: string[] = ['All']

  public sales_reps: string[] = ['All']

  public advertisers: string[] = ['All']

  public agencies: string[] = ['All']

  public stations: string[] = ['All']

  public publishers: string[] = ['All']

  public billing_contracts: string[] = ['All']

  public orders: string[] = ['All']

  public line_items: string[] = ['All']

  public devices: string[] = ['All']

  public advertiser_names: string[] = ['All'] // Media Ocean Property

  public agency_advertiser_codes: string[] = ['All'] // Media Ocean Property

  public product_names: string[] = ['All'] // Media Ocean Property

  public product_codes: string[] = ['All'] // Media Ocean Property

  public agency_estimate_codes: string[] = ['All'] // Media Ocean Property

  public isci_codes: string[] = ['All'] // Media Ocean Property

  public order_types: string = 'All'

  public show_media_ocean_details: boolean = false

  public limit: number = 2

  public get account_executive_names() {
    if (this.account_executives.length > this.limit) {
      return [`Multiple (${this.account_executives.length})`]
    }
    if (this.account_executives.length == 1 && this.account_executives[0] == 'All') {
      return this.account_executives
    }
    const ret = this.account_executives

    if (this.account_executives.includes('N/A')) {
      ret.unshift('N/A')
    }

    return ret
  }

  public get sales_rep_names() {
    if (this.sales_reps.length > this.limit) {
      return [`Multiple (${this.sales_reps.length})`]
    }
    return this.sales_reps
  }

  public get advertisers_names() {
    if (this.advertisers.length > this.limit) {
      return [`Multiple (${this.advertisers.length})`]
    }
    return this.advertisers
  }

  public get agencies_names() {
    if (this.agencies.length > this.limit) {
      return [`Multiple (${this.agencies.length})`]
    }
    return this.agencies
  }

  public get stations_names() {
    if (this.stations.length > this.limit) {
      return [`Multiple (${this.stations.length})`]
    }
    return this.stations
  }

  public get publishers_names() {
    if (this.publishers.length > this.limit) {
      return [`Multiple (${this.publishers.length})`]
    }
    return this.publishers
  }

  public get billing_contracts_names() {
    if (this.billing_contracts.length > this.limit) {
      return [`Multiple (${this.billing_contracts.length})`]
    }
    return this.billing_contracts
  }

  public get orders_names() {
    if (this.orders.length > this.limit) {
      return [`Multiple (${this.orders.length})`]
    }
    return this.orders
  }

  public get line_items_names() {
    if (this.line_items.length > this.limit) {
      return [`Multiple (${this.line_items.length})`]
    }
    return this.line_items
  }

  public get advertiser_names_names() {
    if (this.advertiser_names.length > this.limit) {
      return [`Multiple (${this.advertiser_names.length})`]
    }
    return this.advertiser_names
  }

  public get agency_advertiser_codes_names() {
    if (this.agency_advertiser_codes.length > this.limit) {
      return [`Multiple (${this.agency_advertiser_codes.length})`]
    }
    return this.agency_advertiser_codes
  }

  public get product_names_names() {
    if (this.product_names.length > this.limit) {
      return [`Multiple (${this.product_names.length})`]
    }
    return this.product_names
  }

  public get product_codes_names() {
    if (this.product_codes.length > this.limit) {
      return [`Multiple (${this.product_codes.length})`]
    }
    return this.product_codes
  }

  public get agency_estimate_codes_names() {
    if (this.agency_estimate_codes.length > this.limit) {
      return [`Multiple (${this.agency_estimate_codes.length})`]
    }
    return this.agency_estimate_codes
  }

  public get isci_codes_names() {
    if (this.isci_codes.length > this.limit) {
      return [`Multiple (${this.isci_codes.length})`]
    }
    return this.isci_codes
  }

  public static toObject(data: any): DashboardDataDetail {
    const detail = new DashboardDataDetail()

    detail.account_executives = data.account_executives
    detail.sales_reps = data.sales_reps
    detail.advertisers = data.advertisers
    detail.agencies = data.agencies
    detail.stations = data.stations
    detail.publishers = data.publishers
    detail.billing_contracts = data.billing_contracts
    detail.orders = data.orders
    detail.line_items = data.line_items
    detail.devices = data.devices
    detail.advertiser_names = data.advertiser_names
    detail.agency_advertiser_codes = data.agency_advertiser_codes
    detail.product_names = data.product_names
    detail.agency_estimate_codes = data.agency_estimate_codes
    detail.show_media_ocean_details = data.show_media_ocean_details
    detail.isci_codes = data.isci_codes
    detail.order_types = data.order_types
    detail.product_codes = data.product_codes

    return detail
  }

  public static toObjectList(data: any): DashboardDataDetail[] {
    const details = new Array<DashboardDataDetail>()
    data.forEach((value: any) => {
      const detail = DashboardDataDetail.toObject(value)
      details.push(detail)
    })

    return details
  }
}
