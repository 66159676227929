
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'

@Component({
  components: {
    FormInput,
    SelectPicker,
  },
})
export default class ShareModal extends Vue {
  @Prop({ default: false })
  public show!: boolean

  public local_show: boolean = false

  public show_items: boolean = false

  public show_creatives: boolean = false

  public map_mode: string = 'dma'

  public map_options: object[] = [
    { name: 'DMA', value: 'dma' },
    { name: 'State', value: 'state' },
  ]

  public message: string = ''

  public name: string = ''

  public to: string[] = []

  public option_tos: object[] = []

  public addTag(newTag: any) {
    const tag = {
      name: newTag,
      value: newTag,
    }
    this.option_tos.push(tag)
    this.to.push(newTag)
  }

  @Watch('show')
  public onChangeShow() {
    this.local_show = this.show
  }

  @Watch('local_show')
  public onChangeLocalShow() {
    this.$emit('update:show', this.local_show)
  }

  public handleShareOk() {
    // @ts-ignore
    this.$refs['share-form'].validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.shareConfirm()
    })
  }

  public shareConfirm() {
    this.$emit('confirm', {
      show_items: this.show_items,
      show_creatives: this.show_creatives,
      map: this.map_mode,
      message: this.message,
      name: this.name,
      to: this.to,
    })
    this.local_show = false
  }
}
