
import { Component, Watch, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Filter from '@/models/Filter'
import Api from '@/models/Api'
import DatePickerDate from '@/models/DatePickerDate'
import moment from 'moment'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
import ReportViewPicker from '@/components/ReportViewPicker/ReportViewPicker.vue'
import { clone, uniqueId } from 'lodash'
import settings from './settings'
import Details from './components/Cards/Details.vue'
import DownloadModal from './components/DownloadModal.vue'
import ShareModal from './components/ShareModal.vue'
import Card from './components/Card.vue'

@Component({
  components: {
    Card,
    Details,
    IconAction,
    DownloadModal,
    ShareModal,
    ReportViewPicker,
  },
})
export default class Insights extends ViewModel {
  public ready = false

  public filters = new Filter()

  public details: any = null

  public insights_settings: any = null

  public clipboard: string = ''

  public show_items: boolean = false

  public show_creatives: boolean = false

  public no_tables: boolean = false

  public cards: any = []

  public request_id = ''

  public modal: any = {
    send: false,
    download: false,
  }

  public ready_to_print: boolean = false

  private key_data: any = {}

  private enableReach: string[] = [
    '8e3e41ea-e22a-4f6f-8e34-a557b2fcae57',
    '93966b13-066a-4e53-a901-e219793a3186',
    '8e3e41ea-927d-4a19-be60-39c856c5b212',
  ]

  public get loading_dasboard(): boolean[] {
    return this.system.loading.dashboard
  }

  public get isLoading() {
    return this.details.loading || this.cards.some((card: any) => card.loading)
  }

  public get query() {
    const query: any = []
    const params = this.buildParams()
    Object.keys(params).forEach((key: string) => {
      // @ts-ignore
      if (Array.isArray(params[key])) {
        // @ts-ignore
        params[key].forEach((i: string) => {
          query.push({
            name: key,
            value: i,
          })
        })
      } else {
        query.push({
          name: key,
          // @ts-ignore
          value: params[key],
        })
      }
    })

    return query.reduce((q: string, i: any) => {
      if (q) {
        q += '&'
      }

      return `${q + i.name}=${i.value}`
    }, '')
  }

  @Watch('filters.view')
  public onViewChange() {
    this.run()
  }

  @Watch('filters.dmas')
  public onDMASChange(val: any) {
    if (val && val.length) {
      this.filters.map_mode = 'dma'
    }
  }

  @Watch('filters.states')
  public onStatesChange(val: any) {
    if (val && val.length) {
      this.filters.map_mode = 'state'
    }
  }

  public run(filters: Filter | null = null) {
    if (filters) this.filters = new Filter(filters)

    const uuid = uniqueId()
    this.request_id = clone(uuid)
    this.updateCards()
    const cards = [...this.cards.map((c: any) => c.sub_type ?? c.type), 'details']
    const api = new Api()
    let promises: Promise<any>[] = []
    this.ready_to_print = false
    cards.forEach(card_code => {
      let card = this.cards.find((c: any) => c.type === card_code || c.sub_type === card_code)
      if (card) {
        card.loading = true
      } else if (card_code === 'details') {
        this.details.loading = true
      }
      promises.push(
        api
          .get('insights-lazy', {
            ...this.buildParams(),
            cards: [card_code],
          })
          .then((response: any) => {
            if (response.data.async) {
              this.key_data[response.data.key] = {
                uuid,
                card_code,
                card,
              }
              return
            }

            this.processReportData(uuid, card_code, card, response.data)
          })
          .catch(() => this.updateLoading(false)),
      )
    })

    Promise.allSettled(promises).then((responses: any) => {
      this.ready_to_print = true
    })
  }

  public processReportData(uuid: string, card_code: string, card: any, data: any) {
    if (uuid !== this.request_id) {
      return
    }

    if (this.user.isSystem && !this.insights_settings) {
      this.insights_settings = data.insights_settings
      this.updateCards()
    } else {
      this.insights_settings = this.user.company?.insights_settings
      this.updateCards()
    }

    if (card_code === 'details') {
      this.details.data = data.details
      this.details.loading = false
    } else {
      card.data = data[card_code]
      card.loading = false
    }
  }

  public processDataRequest(uuid: string, response: any, data: any) {
    if (uuid !== this.request_id) {
      return
    }
    for (const card of this.cards) {
      const key = card.sub_type ?? card.type
      if (response.data[key]) {
        card.callback_data = {
          cb: data.cb,
          data: response.data[key],
        }
      }
    }
  }

  public onDataRequest(data: any) {
    const api = new Api()
    api
      .get('insights-lazy', { ...this.buildParams(), ...data.request })
      .then((response: any) => {
        if (response.data.async) {
          this.key_data[response.data.key] = {
            uuid: this.request_id,
            data_request: true,
            data,
          }
          return
        }
        this.processDataRequest(this.request_id, response, data)
      })
      .catch(() => this.updateLoading(false))
  }

  public onModeChange(data: any) {
    if (data.card === 'map_chart') {
      this.filters.map_mode = data.mode
    }
  }

  public beforeDestroy() {
    Vue.prototype.$echo.private(`user.${this.user!.id}`).stopListening('AsyncReport')
  }

  public created() {
    Vue.prototype.$echo.private(`user.${this.user!.id}`).listen('AsyncReport', (e: any) => {
      if (!e.instance_id || e.instance_id === this.instance_id) {
        let key_info = this.key_data[e.key]
        const api = new Api()

        api.get(`insights-async/${e.key}`).then((response: any) => {
          if (key_info.data_request) {
            this.processDataRequest(key_info.uuid, response, key_info.data)
            return
          }

          this.processReportData(key_info.uuid, key_info.card_code, key_info.card, response.data)
        })
      }
    })

    this.details = settings.details
    this.cards = settings.cards

    // if (!this.user.can('insights')) {
    //   // this.$router.push({ name: 'ForecastHome' })
    // }

    if (this.user.type === 'sales') {
      // @ts-ignore
      this.filters.sales_reps.push(this.user.id)
    }

    const { query } = this.$route

    if (
      query.start
      && query.end
      && typeof query.start === 'string'
      && typeof query.end === 'string'
    ) {
      this.filters.date = new DatePickerDate(query.start, query.end, null, 'YYYY-MM-DD')
    }

    if (query.agencies) this.filters.agencies = this.formatFilter(query.agencies)
    if (query.account_executives) {
      this.filters.account_executives = this.formatFilter(query.account_executives)
    }
    if (query.sales_reps) this.filters.sales_reps = this.formatFilter(query.sales_reps)
    if (query.advertisers) this.filters.advertisers = this.formatFilter(query.advertisers)
    if (query.stations) this.filters.stations = this.formatFilter(query.stations)
    if (query.publishers) this.filters.publishers = this.formatFilter(query.publishers)
    if (query.line_items) this.filters.line_items = this.formatFilter(query.line_items)
    if (query.devices) this.filters.devices = this.formatFilter(query.devices)
    if (query.orders) this.filters.orders = this.formatFilter(query.orders)
    if (query.dmas) this.filters.dmas = this.formatFilter(query.dmas)
    if (query.states) this.filters.states = this.formatFilter(query.states)
    if (query.advertiser_name) {
      this.filters.advertiser_name = this.formatFilter(query.advertiser_name)
    }
    if (query.agency_advertiser_code) {
      this.filters.agency_advertiser_code = this.formatFilter(query.agency_advertiser_code)
    }
    if (query.product_name) this.filters.product_name = this.formatFilter(query.product_name)
    if (query.agency_estimate_code) {
      this.filters.agency_estimate_code = this.formatFilter(query.agency_estimate_code)
    }
    if (query.isci_codes) this.filters.isci_code = this.formatFilter(query.isci_codes)
    if (query.billing_contracts) {
      this.filters.billing_contracts = this.formatFilter(query.billing_contracts)
    }
    if (query.map && typeof query.map === 'string') this.filters.map_mode = query.map

    if (query.target && typeof query.target === 'string') {
      this.filters.target = query.target
    }

    if (query.show_items) {
      this.show_items = query.show_items === 'true' || query.show_items === '1'
    }

    if (query.show_creatives) {
      this.show_creatives = query.show_creatives === 'true' || query.show_creatives === '1'
    }

    if (query.no_tables) {
      this.no_tables = query.no_tables === 'true' || query.no_tables === '1'
    }

    this.details = settings.details

    this.updateCards()

    if (this.user.report_view_id) {
      this.filters.view = this.user.report_view_id
    } else if (query.view && query.view !== 'null' && typeof query.view === 'string') {
      this.filters.view = query.view
    } else {
      this.run()
    }

    this.ready = true
  }

  public updateCards() {
    // Filter out disabled widgets
    const cards = settings.cards
      .filter((c: any) => {
        if (this.no_tables && c.table) return false

        if (this.user.isSystem && this.insights_settings) {
          return this.insights_settings[c.key] && this.insights_settings[c.key].enabled
            ? this.insights_settings[c.key].enabled
            : false
        }
        return (
          this.user.company?.insightStatus(c.key)
          && (typeof c.visible === 'undefined' || c.visible(this.filters))
        )
      })
      .map(c => {
        if (this.insights_settings) {
          c.settings = this.insights_settings[c.key]

          // Overwrite Reach data for testing (BETA USERS)
          if (typeof this.user.id === 'string' && this.enableReach.includes(this.user.id)) {
            if (c.key === 'impressions') {
              c.settings.unique_viewers = true
              c.settings.frequency = true
            } else if (c.key === 'map') {
              c.settings.reach = true
            }
          }
        }

        const { query } = this.$route
        if (c.key === 'publishers' && query.placementSortBy) {
          // @ts-ignore
          c.sortBy = query.placementSortBy
          c.sortDesc = query.placementSortDesc === 'true'
        } else if (c.key === 'orders' && query.orderSortBy) {
          // @ts-ignore
          c.sortBy = query.orderSortBy
          c.sortDesc = query.orderSortDesc === 'true'
        }

        return c
      })

    // Resize widgets & apply modes
    this.cards = this.checkCards(cards)
  }

  public checkCards(cards: any) {
    // Resize widgets & apply modes
    const has_impressions = cards.some((i: any) => i.key === 'impressions')
    const has_day_distribution = cards.some((i: any) => i.key === 'day_distribution')
    const has_devices = cards.some((i: any) => i.key === 'devices')
    const has_hour_distribution = cards.some((i: any) => i.key === 'hour_distribution')

    if (!has_day_distribution && !has_impressions) {
      this.details.size = { ...this.details.size, lg: 12, md: 12 }
    }

    cards.forEach((c: any) => {
      // Resize widgets
      switch (c.key) {
        case 'impressions':
          if (!has_day_distribution) c.size.lg = { ...c.size, lg: 8, md: 6 }
          break
        case 'day_distribution':
          if (!has_impressions) c.size.lg = { ...c.size, lg: 8, md: 6 }
          break
        case 'devices':
          if (!has_hour_distribution) c.size.lg = 12
          break
        case 'hour_distribution':
          if (!has_devices) c.size = { ...c.size, lg: 12, md: 12 }
          break
        default:
      }

      // Apply Modes
      if (c.type === 'map_chart') {
        c.mode = this.filters.map_mode
      } else if (c.type === 'order_view') {
        c.mode = this.show_creatives ? 'creative' : this.show_items ? 'item' : 'default'
      }
    })

    return cards
  }

  public formatFilter(data: any) {
    if (Array.isArray(data)) {
      return data
    }
    return [data]
  }

  private updateLoading(status: boolean = true) {
    for (const card of this.cards) {
      card.loading = status
    }
    this.details.loading = status
  }

  private buildParams(output: string = 'query', report: string = 'insights') {
    if (!this.filters.target && this.user) {
      this.filters.target = this.user.id
    }

    const result: any = {
      start: moment(this.filters.date.start_date_string).format('YYYY-MM-DD'),
      end: moment(this.filters.date.end_date_string).format('YYYY-MM-DD'),
      line_items: this.filters.line_items,
      publishers: this.filters.publishers,
      map: this.filters.map_mode,
      devices: this.filters.devices,
      account_executives: this.filters.account_executives,
      product_name: this.filters.product_name,
      advertiser_name: this.filters.advertiser_name,
      agency_advertiser_code: this.filters.agency_advertiser_code,
      agency_estimate_code: this.filters.agency_estimate_code,
      agency_product_code: this.filters.agency_product_code,
      isci_codes: this.filters.isci_code,
      sales_reps: this.filters.sales_reps,
      order_type: this.filters.order_type,
      advertisers: this.filters.advertisers,
      agencies: this.filters.agencies,
      stations: this.filters.stations,
      orders: this.filters.orders,
      billing_contracts: this.filters.billing_contracts,
      target: this.filters.target,
      output,
      report,
      show_items: this.show_items,
      show_creatives: this.show_creatives,
      view: this.filters.view,
      instance_id: this.instance_id,
      dmas: this.filters.dmas,
      states: this.filters.states,
      orderSortBy: this.cards.find((c: any) => c.key === 'orders')?.sortBy,
      orderSortDesc: this.cards.find((c: any) => c.key === 'orders')?.sortDesc,
      placementSortBy: this.cards.find((c: any) => c.key === 'publishers')?.sortBy,
      placementSortDesc: this.cards.find((c: any) => c.key === 'publishers')?.sortDesc,
    }

    if (output !== 'mail') {
      delete result.message
      delete result.name
      delete result.to
    }

    return result
  }

  public download() {
    this.modal.download = true
  }

  public confirmDownload(data: any) {
    if (data.mode === 'orders') {
      this.exportOrders()
    } else {
      delete data.mode
      this.exportInsights(data)
    }
  }

  public getURL() {
    return `https://dashboard.revvimedia.com/app/insights?${this.query}`
  }

  public share() {
    this.modal.send = true
  }

  public shareConfirm(data: any) {
    this.modal.send = false
    this.system.startLoading('dashboard')
    const api = new Api()
    api
      .get('insights-lazy', {
        ...this.buildParams('mail', 'insights'),
        ...data,
      })
      .then(() => {
        WebMessage.success('Report sent!')
        this.system.finishLoading('dashboard')
      })
      .catch(() => this.system.finishLoading('dashboard'))
  }

  public shareUrl() {
    this.clipboard = this.getURL()
    // @ts-ignore
    this.$refs.clipboard.setAttribute('type', 'text')
    // @ts-ignore
    this.$refs.clipboard.select()

    let status = false

    try {
      status = document.execCommand('copy')
    } catch (e) {
      status = false
    }

    // @ts-ignore
    this.$refs.clipboard.setAttribute('type', 'hidden')

    if (status) {
      WebMessage.success('The URL was copied to your clipboard!')
    } else {
      WebMessage.error(
        `Ops! We were not able to copy to your clipboard, please copy: ${this.clipboard}`,
      )
    }
  }

  public exportOrders() {
    this.updateLoading(true)

    const api = new Api()
    api
      .get('insights-export-orders', { ...this.buildParams() })
      .then((response: any) => {
        WebMessage.success('Generating Export Data, do not close this window!')
        this.updateLoading(false)
      })
      .catch(() => this.updateLoading(false))
  }

  public exportInsights(data: any) {
    this.system.startLoading('dashboard')
    const api = new Api()
    api
      .get('insights-lazy', {
        ...this.buildParams('download', 'insights'),
        ...data,
      })
      .then((response: any) => {
        WebMessage.success('Generating Report, do not close this window!')
      })
      .catch(() => {
        this.system.finishLoading('dashboard')
      })
  }
}
