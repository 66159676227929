
import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import dmas from '@/data/dmas'
import dmaCoordinates from './dmaSVG'
import stateCoordinates from './stateSVG'

@Component({})
export default class MapChart extends ViewModel {
  public MIN_IMPRESSIONS = 50;

  @Prop()
  public data!: any;

  @Prop()
  public settings!: any;

  @Prop({ default: 'dma' })
  public mode!: string;

  public local_data: any = [];

  @Watch('data')
  public onChangeData() {
    this.buildMap()
  }

  public get coordinates(): any {
    return this.mode === 'dma' ? dmaCoordinates : stateCoordinates
  }

  public getColor(impressions: number, max: number) {
    if (impressions <= this.MIN_IMPRESSIONS) return '#e5e7f1'
    const step = max / 6
    if (impressions >= step * 5) {
      return '#00e397'
    } if (impressions >= step * 4) {
      return '#20e6a3'
    } if (impressions >= step * 3) {
      return '#55e6b4'
    } if (impressions >= step * 2) {
      return '#70e6bd'
    } if (impressions >= step) {
      return '#8ae6c6'
    }

    return '#9ee6cd'
  }

  public buildMap() {
    const map_data = []
    let code: any
    const max = this.data.reduce(
      (a: any, b: any) => (a > b.impressions ? a : b.impressions),
      0,
    )

    for (code in this.coordinates.shapes) {
      let data = this.data.find((d: any) => d.code == code)
      if (!data) {
        let name = null
        if (!isNaN(code)) name = dmas.find((d: any) => d.id == code)?.name
        data = { impressions: 0, code, name }
      }

      map_data.push({
        ...data,
        // @ts-ignore
        coordinates: this.coordinates.shapes[code],
        fill: this.getColor(data.impressions, max),
      })
    }

    this.local_data = map_data
  }

  public mounted() {
    this.buildMap()
  }
}
