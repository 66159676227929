
import { Component, Prop, Watch } from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import ViewModel from '@/models/ViewModel'
import moment from 'moment'

@Component({
  components: {
    IconAction,
  },
})
export default class ItemData extends ViewModel {
  @Prop({ default: () => [] })
  public data: any

  @Prop({
    default: () => ({}),
  })
  public callback_data!: any

  @Prop({ default: false })
  public busy!: boolean

  @Prop({ default: null })
  public settings!: any

  private local_loading: boolean = false

  public local_data: any = []

  @Prop({ default: 'default' })
  public mode!: string

  @Prop()
  public advertiser_id!: string

  @Prop({ default: () => '' })
  public advertiser!: string

  @Prop({ default: '' })
  public sortBy!: any

  @Prop({ default: false })
  public sortDesc!: any

  public get localSortBy() {
    return this.sortBy
  }

  public set localSortBy(value: any) {
    this.$emit('update:sortBy', value)
  }

  public get localSortDesc() {
    return this.sortDesc
  }

  public set localSortDesc(value: any) {
    this.$emit('update:sortDesc', value)
  }

  @Watch('data')
  public onDataChange() {
    this.local_data = this.data.map((r: any) => {
      const ret = {
        ...r,
        loading: false,
        _showDetails: this.mode !== 'default',
      }

      if (ret.items && ret.items.length > 0) {
        ret.items[0] = {
          ...ret.items[0],
          loading: false,
          _showDetails: false,
        }
      }

      if (this.mode === 'creative') {
        ret.items.forEach((i: any) => {
          i._showDetails = true
        })
      }

      return ret
    })
  }

  @Watch('callback_data')
  public onCallback() {
    if (this.callback_data) {
      // If the callback_data.data is empty, stop the loading
      if (Array.isArray(this.callback_data.data) && !this.callback_data.data.length) {
        this.local_data.forEach((order: any) => {
          order.items.map((i: any) => {
            i.loading = false
            i._showDetails = false
            i.creatives = []
            return i
          })
        })
        return
      }

      for (const id in this.callback_data.data) {
        this.local_data.forEach((order: any) => {
          if (this.callback_data.cb.type !== 'item') {
            if (id === order.order_id) {
              order.items = this.callback_data.data[id]
                .filter((item: any) => item.impressions > 0)
                .map((item: any) => ({
                  creatives: [],
                  loading: false,
                  _showDetails: false,
                  ...item,
                }))
              order.loading = false
              order._showDetails = !order._showDetails
            }
          } else {
            order.items.forEach((item: any) => {
              if (id === item.item_id) {
                item.creatives = this.callback_data.data[id]
                item.loading = false
                item._showDetails = !item._showDetails
              }
            })
          }
        })
      }

      this.local_loading = false
    }
  }

  public fields(type: string): object[] {
    const columns: any = [
      {
        key: 'name',
        label: type === 'order' ? 'Order' : type === 'creative' ? 'Creative' : 'Line Item',
        sortable: true,
      },
    ]
    if (type != 'creative') {
      columns.push(
        {
          key: 'flight',
          label: 'Ends',
          sortable: true,
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'goal',
          label: 'Goal',
          sortable: true,
          class: 'text-center',
          thStyle: 'width: 15%',
        },
      )
    }
    columns.push(
      {
        key: 'impressions',
        label: 'Impressions',
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 15%',
      },
      {
        key: 'completion_rate',
        label: 'Completion Rate',
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 15%',
      },
    )

    if (
      type != 'creative'
      && this.user
      && typeof this.user.id === 'string'
      && this.settings.frequency
    ) {
      columns.push({
        key: 'frequency',
        label: 'Frequency ²',
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 15%',
      })
    }

    if (
      type == 'order'
      && this.user
      && typeof this.user.id === 'string'
      && this.settings.reach_increment
    ) {
      columns.push({
        key: 'reach_increment',
        label: 'Incremental Reach ²',
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 15%',
      })
    }

    if (
      type == 'order'
      && this.user
      && typeof this.user.id === 'string'
      && this.settings.coviewing
    ) {
      columns.push({
        key: 'coviewing',
        label: 'Coviewing ²',
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 15%',
      })
    }

    if (type != 'creative') {
      columns.push({
        key: 'details',
        label: 'Details',
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 10%',
      })
    }

    return columns
  }

  public expand_items: boolean = false

  public expand_creatives: boolean = false

  public endInStatus(start: any, end: any) {
    end = moment(end).endOf('day')
    start = moment(start).startOf('day').add(1, 'minutes')
    const today = moment().startOf('day')

    // Check if campaign has ended or not started
    if (today.isBefore(start)) {
      return 'Not Started'
    }
    if (today.isAfter(end)) {
      return 'Ended'
    }

    // Get remaining days
    const diff = end.add(1, 'days').diff(today, 'days')
    if (diff === 0) {
      return 'Today'
    }
    if (diff === 1) {
      return 'Tomorrow'
    }
    return end.fromNow()
  }

  public toggleItems() {
    this.expand_items = !this.expand_items
    this.local_data.forEach((o: any) => {
      o._showDetails = this.expand_items
    })
  }

  public toggleCreatives(items: any[]) {
    this.expand_creatives = !this.expand_creatives
    items.forEach(o => {
      o._showDetails = this.expand_creatives
    })
  }

  public itemName(item: any) {
    return item.order
  }

  public progressColor(item: any) {
    if (!this.user.isSystem) {
      if (new Date(item.end_at) >= new Date()) {
        if (item.pacing < item.required_pacing * 0.7) {
          return 'danger'
        }
        if (item.pacing < item.required_pacing * 0.9) {
          return 'warning'
        }
        if (item.pacing >= item.required_pacing * 1.15) {
          // return 'info' // Disabled
        }
      }
    }

    return 'primary'
  }

  public expandItems(type: string, row: any = null) {
    let request_data: any = {}
    if (type === 'advertiser') {
      this.expand_items = !this.expand_items
      const empty_items = this.local_data.filter((o: any) => o.items.length === 0)

      // Expand items with data
      this.local_data
        .filter((o: any) => o.items.length !== 0)
        .forEach((o: any) => {
          o._showDetails = this.expand_items
        })

      // Stop process when no missing data is found
      if (empty_items.length === 0) return

      this.local_loading = true

      // Build Request Data
      request_data = {
        orders: empty_items.map((o: any) => o.order_id),
        get_items: true,
      }
    } else if (type === 'order') {
      // Toggle details if select item already has its items
      if (row.item.items.length > 0) {
        row.item._showDetails = !row.item._showDetails
        return
      }

      row.item.loading = true
      request_data = {
        orders: [row.item.order_id],
        get_items: true,
      }
    } else if (type === 'item') {
      // Toggle details if select item already has its items
      if (row.item.creatives && (row.item.creatives.length > 0 || row.item.impressions === 0)) {
        row.item._showDetails = !row.item._showDetails
        return
      }
      row.item.loading = true
      request_data = {
        get_creatives: true,
        line_items: [],
      }

      if (row.item.item_id) {
        request_data.line_items = [row.item.item_id]
      } else {
        request_data.advertisers = [this.advertiser_id]
      }
    }

    this.$parent.$parent.$parent.$parent.$emit('requestData', {
      cb: {
        advertiser_id: this.advertiser_id,
        type,
      },
      request: {
        cards: ['order_view'],
        ...request_data,
      },
    })
  }

  public mounted() {
    this.onDataChange()
  }
}
