
import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {},
})
export default class Table extends ViewModel {
  @Prop({ default: null })
  public message!: any
}
