var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"card my-2"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.advertiser)+" "),(_vm.local_loading)?_c('b-spinner',{staticClass:"float-right",attrs:{"label":"Loading...","variant":"primary","small":""}}):_c('IconAction',{staticClass:"float-right",attrs:{"title":"Expand Items","icon":_vm.expand_items ? 'minus' : 'plus'},on:{"click":function($event){return _vm.expandItems('advertiser')}}})],1),(_vm.local_data.length > 0)?_c('b-table',{attrs:{"items":_vm.local_data,"fields":_vm.fields('order'),"striped":"","show-empty":"","responsive":"","sort-by":_vm.localSortBy,"sort-desc":_vm.localSortDesc},on:{"update:sortBy":function($event){_vm.localSortBy=$event},"update:sort-by":function($event){_vm.localSortBy=$event},"update:sortDesc":function($event){_vm.localSortDesc=$event},"update:sort-desc":function($event){_vm.localSortDesc=$event}},scopedSlots:_vm._u([{key:"cell(order)",fn:function(row){return [_vm._v(" "+_vm._s(row.value)+" ")]}},{key:"cell(impressions)",fn:function(row){return [_c('span',{attrs:{"id":`tooltip-order-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`}},[_vm._v(" "+_vm._s(_vm._f("abbreviate")(row.value))+" "),(row.item.goal !== null && row.item.goal > 0)?_c('b-progress',{staticClass:"bg-white progress-xs",attrs:{"variant":"primary","value":row.item.metrics.impressions,"max":row.item.goal}}):_vm._e()],1),(row.item.goal !== null && row.item.goal > 0)?_c('b-tooltip',{attrs:{"target":`tooltip-order-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`,"triggers":"hover"}},[(row.item.metrics.impressions > row.item.goal)?_c('span',[_vm._v("100%")]):_c('span',[_vm._v(_vm._s(_vm._f("percentage")((row.item.metrics.impressions / row.item.goal))))]),_c('br'),(row.item.start_at !== null && row.item.end_at)?_c('span',[_vm._v("Pacing: "+_vm._s(_vm._f("abbreviate")(row.item.metrics.pacing.current))+" / Req: "+_vm._s(_vm._f("abbreviate")(row.item.metrics.pacing.required)))]):_vm._e()]):_vm._e()]}},{key:"cell(completion_rate)",fn:function(row){return [(parseInt(row.item.completed) < parseInt(row.item.start))?_c('span',[_vm._v(_vm._s(_vm._f("percentage")((row.item.completed / row.item.start))))]):_c('span',[_vm._v("100%")])]}},{key:"cell(details)",fn:function(row){return [(row.item.order_id && (_vm.local_loading || row.item.loading))?_c('b-spinner',{attrs:{"label":"Loading...","variant":"primary","small":""}}):_c('IconAction',{attrs:{"title":"Expand Items","icon":"search"},on:{"click":function($event){return _vm.expandItems('order', row)}}})]}},{key:"cell(flight)",fn:function(row){return [_c('span',{attrs:{"id":`tooltip-order-flight-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`}},[(row.item.end_at)?_c('span',[_vm._v(" "+_vm._s(_vm.endInStatus(row.item.start_at, row.item.end_at))+" ")]):_c('span',[_vm._v(" Open Flight Dates ")])]),_c('b-tooltip',{attrs:{"target":`tooltip-order-flight-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`,"triggers":"hover"}},[(row.item.start_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.item.start_at,'MM/DD/YYYY'))+" - "),(row.item.end_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.item.end_at,'MM/DD/YYYY'))+" ")]):_c('span',[_vm._v("Open")])]):_c('span',[_vm._v(" Open Flight Dates ")])])]}},{key:"cell(goal)",fn:function(row){return [(row.value === null || row.value === '')?_c('span',[_vm._v("N/A ")]):(row.value === 0)?_c('span',[_vm._v("Open")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("abbreviate")(row.value))+" ")])]}},{key:"cell(frequency)",fn:function(row){return [(row.value === null || row.value === '' || row.value === 0)?_c('span',[_vm._v("N/A")]):_c('span',{attrs:{"id":`tooltip-order-frequency-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`}},[_vm._v(" "+_vm._s(_vm._f("number")(row.value,'0.00'))+" ")]),(row.value > 0 && false)?_c('b-tooltip',{attrs:{"target":`tooltip-order-frequency-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm._f("abbreviate")(row.item.unique_users))+" Unique Users ")]):_vm._e()]}},{key:"cell(coviewing)",fn:function(row){return [(row.value === null || row.value === '' || row.value === 0)?_c('span',[_vm._v("N/A")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("number")(row.value,'0.00'))+" ")])]}},{key:"cell(reach_increment)",fn:function(row){return [(row.value === null || row.value === '' || row.value === 0)?_c('span',[_vm._v("N/A")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("percentage")(row.value))+" ")])]}},{key:"cell(unique_users)",fn:function(row){return [(
              row.item.frequency === null || row.item.frequency === '' || row.item.frequency === 0
            )?_c('span',[_vm._v("N/A")]):_c('span',{attrs:{"id":`tooltip-order-unique-users-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`}},[_vm._v(" "+_vm._s(_vm._f("abbreviate")(row.value))+" ")]),(row.item.frequency > 0)?_c('b-tooltip',{attrs:{"target":`tooltip-order-unique-users-${row.item.name.replace(/\s/g, '_')}-${
              row.item.order_id
            }-${_vm.advertiser_id}`,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm._f("number")(row.item.frequency,'0.00'))+" impressions / user ")]):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-table',{attrs:{"items":row.item.items,"fields":_vm.fields('item'),"striped":"","show-empty":"","responsive":"","thead-class":"white-bg","sort-by":_vm.localSortBy,"sort-desc":_vm.localSortDesc},on:{"update:sortBy":function($event){_vm.localSortBy=$event},"update:sort-by":function($event){_vm.localSortBy=$event},"update:sortDesc":function($event){_vm.localSortDesc=$event},"update:sort-desc":function($event){_vm.localSortDesc=$event}},scopedSlots:_vm._u([{key:"head(frequency)",fn:function(){return [_vm._v(" Frequency ²"),_c('br'),_c('small',[_vm._v("(Average / Lifetime)")])]},proxy:true},{key:"cell(impressions)",fn:function(r){return [_c('span',{attrs:{"id":`tooltip-item-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.id
                    }-${_vm.advertiser_id}`}},[_vm._v(" "+_vm._s(_vm._f("abbreviate")(r.value))+" "),(r.item.goal !== null && r.item.goal > 0)?_c('b-progress',{staticClass:"bg-white progress-xs",attrs:{"variant":_vm.progressColor(r.item),"value":r.item.metrics.impressions,"max":r.item.goal}}):_vm._e()],1),(r.item.goal !== null && r.item.goal > 0)?_c('b-tooltip',{attrs:{"target":`tooltip-item-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.id
                    }-${_vm.advertiser_id}`,"triggers":"hover"}},[(r.item.metrics.impressions > r.item.goal)?_c('span',[_vm._v("100%")]):_c('span',[_vm._v(_vm._s(_vm._f("percentage")((r.item.metrics.impressions / r.item.goal))))]),_c('br'),(r.item.start_at !== null && r.item.end_at)?_c('span',[_vm._v("Pacing: "+_vm._s(_vm._f("abbreviate")(r.item.metrics.pacing.current))+" / Req: "+_vm._s(_vm._f("abbreviate")(r.item.metrics.pacing.required)))]):_vm._e()]):_vm._e()]}},{key:"cell(completion_rate)",fn:function(r){return [(parseInt(r.item.completed) < parseInt(r.item.start))?_c('span',[_vm._v(_vm._s(_vm._f("percentage")((r.item.completed / r.item.start))))]):_c('span',[_vm._v("100%")])]}},{key:"cell(model)",fn:function(r){return [_vm._v(" "+_vm._s(_vm._f("uppercase")(r.value))+" ")]}},{key:"cell(flight)",fn:function(r){return [_c('span',{attrs:{"id":`tooltip-item-flight-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.id
                    }-${_vm.advertiser_id}`}},[(r.item.end_at !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.endInStatus(r.item.start_at, r.item.end_at))+" ")]):_c('span',[_vm._v(" Open Flight Dates ")])]),_c('b-tooltip',{attrs:{"target":`tooltip-item-flight-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.id
                    }-${_vm.advertiser_id}`,"triggers":"hover"}},[(r.item.start_at !== null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(r.item.start_at,'MM/DD/YYYY'))+" - "),(r.item.end_at !== null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(r.item.end_at,'MM/DD/YYYY'))+" ")]):_c('span',[_vm._v("Open")])]):_c('span',[_vm._v(" Open Flight Dates ")])])]}},{key:"cell(goal)",fn:function(row){return [(row.value === null || row.value === '')?_c('span',[_vm._v("N/A ")]):(row.value === 0)?_c('span',[_vm._v("Open")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("abbreviate")(row.value))+" ")])]}},{key:"cell(details)",fn:function(r){return [(r.item.loading)?_c('b-spinner',{attrs:{"label":"Loading...","variant":"primary","small":""}}):_c('IconAction',{attrs:{"title":"Expand Creatives","icon":"search"},on:{"click":function($event){return _vm.expandItems('item', r)}}})]}},{key:"cell(frequency)",fn:function(r){return [(r.value === null || r.value === '' || r.value === 0)?_c('span',[_vm._v("N/A")]):_c('span',{attrs:{"id":`tooltip-item-frequency-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.item_id
                    }-${_vm.advertiser_id}`}},[_vm._v(" "+_vm._s(_vm._f("number")(r.item.daily_frequency,'0.00'))+" / "+_vm._s(_vm._f("number")(r.value,'0.00'))+" ")]),(r.value > 0 && false)?_c('b-tooltip',{attrs:{"target":`tooltip-item-frequency-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.item_id
                    }-${_vm.advertiser_id}`,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm._f("abbreviate")(r.item.unique_users))+" Unique Users ")]):_vm._e()]}},{key:"cell(unique_users)",fn:function(r){return [(
                      r.item.frequency === null ||
                        r.item.frequency === '' ||
                        r.item.frequency === 0
                    )?_c('span',[_vm._v("N/A")]):_c('span',{attrs:{"id":`tooltip-item-unique-users-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.item_id
                    }-${_vm.advertiser_id}`}},[_vm._v(" "+_vm._s(_vm._f("abbreviate")(r.value))+" ")]),(r.item.frequency > 0)?_c('b-tooltip',{attrs:{"target":`tooltip-item-unique-users-${r.item.name.replace(/\s/g, '_')}-${
                      r.item.item_id
                    }-${_vm.advertiser_id}`,"triggers":"hover"}},[_vm._v(" "+_vm._s(row.item.frequency)+" impressions / user ")]):_vm._e()]}},{key:"row-details",fn:function(item){return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-table',{attrs:{"items":item.item.creatives,"fields":_vm.fields('creative'),"striped":"","show-empty":"","responsive":"","thead-class":"white-bg"},scopedSlots:_vm._u([{key:"cell(impressions)",fn:function(r){return [_vm._v(" "+_vm._s(_vm._f("abbreviate")(r.value))+" ")]}},{key:"cell(completion_rate)",fn:function(r){return [(r.item.completed > r.item.start)?_c('span',[_vm._v("100%")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("percentage")((r.item.completed / r.item.start)))+" ")])]}}],null,true)})],1)])]}}],null,true)})],1)])]}}],null,false,1321527728)}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }