var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[(_vm.data)?_c('span',[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-lg"},[_c('h2',[_vm._v(_vm._s(_vm._f("separator")(_vm.data.impressions)))])]),_c('div',{staticClass:"d-flex border-top"},[(_vm.settings.completion_rate)?_c('div',{staticClass:"w-50 border-right p-3 px-4"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(parseInt(_vm.data.completed) < parseInt(_vm.data.start))?_c('h6',[_vm._v(" "+_vm._s(_vm._f("percentage")((_vm.data.completed / _vm.data.start)))+" ")]):_c('h6',[_vm._v("100%")]),_c('i',{staticClass:"la la-arrow-right text-success rotate-315"})]),_vm._m(0)]):_vm._e(),(_vm.settings.hours_watched)?_c('div',{staticClass:"w-50 p-3 px-4"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(!_vm.data.watched)?_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Hours Watched is not available with the selected filters"}},[_vm._v(" N/A ")]):_c('h6',[_vm._v(" "+_vm._s(_vm._f("abbreviate_full")(_vm._f("abbreviate")(_vm.data.watched)))+" ")])]),_vm._m(1)]):_vm._e()]),_c('div',{class:{
        'd-flex border-top': true,
        'border-bottom': !_vm.settings.unique_viewers && !_vm.settings.frequency,
      }},[(_vm.settings.live_impressions)?_c('div',{staticClass:"w-50 border-right p-3 px-4"},[(!_vm.data.live_impressions && !_vm.data.non_live_impressions && _vm.data.impressions > 0)?_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Inventory Distribution is not available with state filters"}},[_vm._v(" N/A ")])]):_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',[_vm._v(" "+_vm._s(_vm._f("abbreviate_full")(_vm._f("abbreviate")(_vm.data.live_impressions)))+" ")]),_c('span',{staticClass:"text-muted"},[_vm._v("("+_vm._s(_vm._f("percentage")((_vm.data.live_impressions / _vm.data.impressions)))+")")])]),_vm._m(2)]):_vm._e(),(_vm.settings.non_live_impressions)?_c('div',{staticClass:"w-50 p-3 px-4"},[(!_vm.data.live_impressions && !_vm.data.non_live_impressions && _vm.data.impressions > 0)?_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Inventory Distribution is not available with state filters"}},[_vm._v(" N/A ")])]):_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',[_vm._v(" "+_vm._s(_vm._f("abbreviate_full")(_vm._f("abbreviate")(_vm.data.non_live_impressions)))+" ")]),_c('span',{staticClass:"text-muted"},[_vm._v("("+_vm._s(_vm._f("percentage")((_vm.data.non_live_impressions / _vm.data.impressions)))+")")])]),_vm._m(3)]):_vm._e()]),_c('div',{class:{
        'd-flex border-top': true,
        'border-bottom': _vm.settings.unique_viewers || _vm.settings.frequency,
      }},[(false && _vm.settings.unique_viewers)?_c('div',{staticClass:"w-50 border-right p-3 px-4"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',[_vm._v(_vm._s(_vm._f("abbreviate_full")(_vm._f("abbreviate")(_vm.data.reach))))])]),_vm._m(4)]):_vm._e(),(_vm.settings.frequency)?_c('div',{staticClass:"w-100 p-3 px-4"},[_c('span',{staticClass:"beta"},[_vm._v("beta")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(_vm.data.frequency)?_c('h6',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.data.frequency,'0.00'))+" ")]):_c('h6',[_vm._v("N/A")])]),_vm._m(5)]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-0 mr"},[_c('small',[_vm._v("Completion Rate")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-0 mr"},[_c('small',[_vm._v("Hours watched")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-0 mr"},[_c('small',[_vm._v("Live Games Impressions")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-0 mr"},[_c('small',[_vm._v("Premium Inventory")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-0 mr"},[_c('small',[_vm._v("Unique Viewers")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-0 mr"},[_c('small',[_vm._v("Frequency ")])])
}]

export { render, staticRenderFns }