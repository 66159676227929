
import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {},
})
export default class Overview extends ViewModel {
  @Prop({ default: null })
  public data!: any

  @Prop({ default: null })
  public settings!: any

  @Prop({ default: false })
  public loading!: boolean
}
